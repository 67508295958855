<template>
  <div id="home">
    <van-nav-bar
        title="预约服务"
        left-text="返回"
        left-arrow
        fixed
        @click-left="goback('/home')"
    />
    <van-image class="a-us" :src="banner"></van-image>
    <div class="r-s r-info">
      <h3>预约在线<span>BOOK ONLINE</span></h3>
      <div class="r-s-list">
        <van-cell>
          <template #title>
            <van-image :src="info.pic ? info.pic : avatar"></van-image>
          </template>
          <template #default>
            <div class="title">{{ info.name }}<span>{{ info.gender }}</span><span>{{ info.title }}</span></div>
            <div class="instro r-info-i">
              <span>简介：</span>{{ info.description }}
            </div>
            <div class="instro r-info-i">
              <span>擅长：</span>{{ info.good_at }}
            </div>
            <div class="bottom l-more" @click.stop="showmore">查看更多</div>
          </template>
        </van-cell>
      </div>
      <div class="porjects">
        <h3>项目说明<span>PROJECTS</span></h3>
        <div class="p-l-p">
          <ul>
            <li>项目名称</li>
            <li>项目价格</li>
            <li>项目说明</li>
          </ul>
          <ul v-for="(item,idx) in tolllist">
            <li>{{item.title}}</li>
            <li>
              <div v-if="item.tprice.length > 0">
                <template v-for="kds in item.tprice">
                <div>{{kds.name}}：{{kds.tprice}}</div>
                </template>
              </div>
              <div v-else>{{item.price}}</div>
            </li>
            <li @click.stop="showinfo(item)">查看说明</li>
          </ul>
        </div>
      </div>
      <div class="ytime datetime">
        <div class="c-c">预约时间：</div>
        <div class="c-c-list">
          <template v-for="(item,index) in yydate" :key="index">
            <span v-if="item.lessnum > 0" :class="item.is_choose == 1 ? 'current':''" @click="selectdays(item)">{{item.yksdate}}<br/>剩({{item.lessnum}})</span>
            <span v-else class="forbid" aria-disabled="true">{{item.datetime}}</span>
          </template>
        </div>
        <!--<van-calendar
            title="预约时间"
            @select="getdays"
            :formatter="formatters"
            :poppable="false"
            :show-confirm="false"
            :style="{ height: '500px' }"
        />-->
      </div>
      <div class="datetime">
        <div class="c-c">请选择预约时段：</div>
        <div class="c-c-list">
          <template v-for="(item,index) in times" :key="index">
          <span v-if="item.is_occupy === 0" :class="item.is_choose == 1 ? 'current':''" @click="selecttime(item)">{{item.datetime}}</span>
          <span v-else class="forbid" aria-disabled="true">{{item.datetime}}</span>
          </template>
        </div>
      </div>
      <div class="audio">
        <div class="c-a-i">请核对您的预约：</div>
        <div class="c-a-info">{{ message }}</div>
      </div>
      <div class="audio">
        <div class="c-a-i">预约对象：</div>
        <div class="c-a-info">
          <div>
            <van-radio-group v-model="isself" direction="horizontal" checked-color="#ff0a00">
              <van-radio name="0" shape="square">本人</van-radio>
              <van-radio name="1" shape="square">他人</van-radio>
            </van-radio-group>
          </div>
          <div class="v-field-s" v-if="isself == 1">
            <van-field v-model="oname" label="客户姓名" placeholder="请输入姓名" />
            <van-field v-model="omobile" label="手机号码" placeholder="请输入手机号码" />
          </div>
        </div>
      </div>
      <div class="audio paymethod">
        <div class="c-a-i">请选择支付方式：<span>（优先扣除余额）</span></div>
        <div class="c-a-info">
          <van-radio-group v-model="paymethod" checked-color="#ff0a00">
            <van-radio name="balance">余额支付（当前余额¥ {{ balance }}）</van-radio>
            <van-radio name="wechat">在线微信支付</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="v-btn"><van-button class="sybtn" :loading="loading" @click="dopay">确认预约</van-button></div>
    </div>
    <van-overlay :show="show" @click="closemore">
      <div class="wrapper">
        <div class="block" @click.stop>
          <h3>{{ info.name }}<span>{{info.gender}}</span></h3>
          <div class="i-title">{{ info.title }}</div>
          <div class="infos" v-html="info.info"></div>
        </div>
      </div>
    </van-overlay>
  </div>
  <van-dialog class="s-project" v-model:show="showDailog" :title="showTitle">
    {{showExplain}}
  </van-dialog>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget, httppost} from "@/libs/api";
import { useRouter } from 'vue-router';
import {useRoute} from "vue-router";
import {Toast,Dialog} from "vant";

export default {
  name: 'Home',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      banner: require('@/assets/default/imgs/banner.jpg'),
      avatar: require('@/assets/default/imgs/avatar.png'),
      loadidng:false,
      name: '',
      mid: 0,
      isbusy: false,
      show:false,
      showDailog: false,
      showTitle:'',
      showExplain:'',
      info:[],
      paymethod:['balance'],
      placeholder: '请输入按摩师姓名',
      times:[],
      defaulttimes:[],
      yydate:[],
      tolllist:[],
      message:'',
      choosedate:'',
      choosetime:'',
      balance:'0.00',
      code:'',
      scope:'',
      isself:"0",
      oname:'',
      omobile:'',
      formdata:{
        date:''
      }
    })
    const showinfo = (item) => {
      state.showTitle = item.title;
      state.showExplain = item.info;
      state.showDailog = true;
    }
    const formatters = (day) => {
      const year = day.date.getFullYear();
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      const ymd = year+'-'+month+'-'+date;
      state.yydate.forEach((item)=>{
        if( item.datetime == ymd ){
          day.bottomInfo = '剩'+item.lessnum;
        }
      });
      /*if( day.bottomInfo == undefined || day.bottomInfo <= 0 ){
        day.type = 'disabled';
      }*/
      return day;
    }
    const getinfo = async () => {
      state.isbusy = true;
      let res = await httpget('/api/order/getinfo',{mid:state.mid});
      if( res.code == 0 ){
        state.info = res.data.info;
        state.times = res.data.times;
        state.defaulttimes = JSON.parse(JSON.stringify(res.data.times));
        state.choosedate = new Date();
        state.formdata.date = formatDate(state.choosedate);
        state.yydate = res.data.yydate;
        state.yydate.forEach((date)=>{
          date.yksdate = formatDateDays(new Date(date.datetime));
        });
        state.tolllist = res.data.tolllist;
        state.balance = res.data.balance;
        getchoosetime();
        getyytimestr();
        state.isbusy = false;
      } else {
        Toast.fail(res.msg);
        state.isbusy = false;
        return false;
      }
    }
    const showmore = () => {
      state.show = true;
    }
    const closemore = () => {
      state.show = false;
    }
    const getWeek = (dateString) =>{
      var dateArray = dateString.split("-");
      let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
      return "周" + "日一二三四五六".charAt(date.getDay());
    }
    const formatDate = (date) => {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    const formatDateDays = (date) => {
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
    }
    const dopay = () =>{
      if( !state.choosedate ){
        Toast.fail('请选择预约时间');
        return;
      }
      if( !state.choosetime ){
        Toast.fail('请选择预约时段');
        return;
      }
      if( state.paymethod.length <= 0 ){
        Toast.fail('请选择支付方式');
        return;
      }
      if( state.isself == 1 && (!state.oname || !state.omobile) ){
        Toast.fail('请输入预约人姓名或者手机号码');
        return;
      }
      if( parseFloat(state.balance) < parseFloat(state.info.deposit) && state.paymethod.length == 1 && state.paymethod.indexOf('balance') !== -1 ){
        Dialog.confirm({
          title: '提示',
          message: '余额不足，请先充值或使用在线支付',
          confirmButtonText:'去充值',
        }).then(() => {
          router.push({path: '/wallet'}).catch(err => {err});
        }).catch(() => {
          console.log('cancel');
        });
        return;
      }
      Dialog.confirm({
        title: '确认预约',
        message: state.message,
        confirmButtonText:'去支付',
      })
      .then(async() => {
        let param = {
          mid:state.info.id,
          date:formatDate(state.choosedate),
          time:state.choosetime,
          isself:state.isself,
          oname:state.oname,
          omobile:state.omobile,
          paymethod:state.paymethod
        };
        state.loading = true;
        let res = await httppost('/api/order/create',param);
        if( res.code == 0 ){
          state.loading = false;
          onBridgeReady(res.data);
        } else if( res.code == 200 ) { //预约余额支付
          Toast.success(res.msg);
          setTimeout(function(){
            router.push({path: '/order'}).catch(err => {err});
          },800);
        } else if( res.code == 2 ) {
          state.loading = false;
          state.appid = res.data.appid;
          state.scope = res.data.scope;
          getCode();
        } else {
          Toast.fail(res.msg);
        }
      })
      .catch(() => {
        console.log("cancel");
      });

    }
    const getdays = (value) =>{
      state.choosedate = value;
      let datetime = formatDate(value);
      getyytimestr();
      state.formdata.date = datetime;
      getchoosetime();
    }
    const getchoosetime = async() =>{
      //获取当前技师当天的安排
      let res = await httpget('/api/order/gettime',{mid:state.info.id,time:state.formdata.date});
      if( res.code == 0 ){
        if( res.data.length > 0 ){
          res.data.forEach((t)=>{
            state.times.forEach((s,ids)=>{
              if( t.datetime == s.datetime ){
                state.times[ids].is_occupy = 1;
              }
            })
          });
        } else {
          state.times = JSON.parse(JSON.stringify(state.defaulttimes));
        }
      } else {
        Toast.fail(res.msg);
        return;
      }
    }
    const selecttime = (item) =>{
      let timearr = state.times;
      timearr.forEach((val,ids)=>{
        if( val.datetime == item.datetime ){
          state.choosetime = item.datetime;
          getyytimestr();
          timearr[ids].is_choose = 1;
        } else {
          timearr[ids].is_choose = 0;
        }
      });
      state.times = timearr;
    }
    const selectdays = (item) =>{
      let yydate = state.yydate;
      state.choosedate = new Date(item.datetime);
      state.formdata.date = item.datetime;
      yydate.forEach((val,ids)=>{
        if( val.datetime == item.datetime ){
          yydate[ids].is_choose = 1;
        } else {
          yydate[ids].is_choose = 0;
        }
      });
      getyytimestr();
      getchoosetime();
      state.yydate = yydate;
    }
    const getyytimestr = () =>{
      let sday = '';
      if( state.choosetime ){
        let hour = state.choosetime.split(':');
        sday = '上午';
        if( hour[0] >= 12 ) sday = '下午';
      }
      state.message =  state.info.name+' '+formatDateDays(state.choosedate)+"（"+getWeek(state.formdata.date)+"）"+(sday?sday:'')+' '+(state.choosetime?state.choosetime:'');
    }
    const getCode = () => {
      let local = window.location.href;
      if (state.code == null || state.code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + state.appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope='+state.scope+'&state=1#wechat_redirect'
      }
    }
    const getOpenId = async () =>  {
      let res = await httppost('/api/auth/login', { code: state.code });
      if(res.code===0){
        console.log('success');
      }else{
        console.log('fail');
      }
    }
    const onBridgeReady = (resJson)=>{
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', resJson,
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            router.push({path: '/order'}).catch(err => {err});
          }
      });
    }
    onMounted(() => {
      let mid = route.query.mid? route.query.mid : 0;
      let code = route.query.code? route.query.code : '';
      state.code = code;
      state.mid = mid;
      getinfo();
      if( state.code ){
        getOpenId()
      }
    })

    return {
      ...toRefs(state),
      getinfo,
      showmore,
      formatDate,
      getchoosetime,
      formatDateDays,
      getyytimestr,
      getdays,
      selectdays,
      selecttime,
      onBridgeReady,
      dopay,
      getCode,
      getOpenId,
      formatters,
      getWeek,
      showinfo,
      closemore
    };
  },
}
</script>
